import React from 'react'
import Card from 'react-bootstrap/Card';
import './css/CardS.css'
import { Link } from 'react-router-dom';

function Hcard({ input }) {
    return <>
            <Card className='body-c m-2' style={{  border: "1px solid white", padding: "1px" }} >
                <Card.Img variant="top" src={input.img} />
                <Card.Body className='text-start h-card'>
                    <Link style={{textDecoration:"none" ,color:"white",cursor:"pointer"}} to={input.path}><Card.Title className='ttl'>{input.name}</Card.Title></Link>
                    <Card.Text>
                        {input.description}
                    </Card.Text>
                </Card.Body>
            </Card>
    </>
}

export default Hcard   