import React from 'react'
import { Link } from 'react-router-dom'
import Fade from 'react-reveal/Fade';

function Footer() {
    let value = true ;
    
    return <>
        <div className='container-fluid'   bottom={value.toString()}>
            <div className='row pt-3 d-flex justify-content-around'>
                <div className=' col-md-4'>
                    <div className='h6 pt-2 pb-3'>
                        COMPANY
                    </div>
                    <div >
                        <Link target='blank' className='fs-6' style={{textDecoration:"none",color:"black"}} to='/about'>About Us</Link>
                    </div>
                    <div >
                        <Link target='blank'  className='fs-6' style={{textDecoration:"none",color:"black"}} to='/career'>Careers</Link>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className=' h6 pt-2 pb-3'>
                        SUPPORT
                    </div>
                    <div >
                        <Link target='blank'  className='fs-6' style={{textDecoration:"none",color:"black"}} to='/terms-of-use'>Terms of Use</Link>
                    </div>
                    <div >
                        <Link target='blank'  className='fs-6' style={{textDecoration:"none",color:"black"}} to='/privacy-policy'>Privacy Statement</Link>
                    </div>
                    <div >
                        <Link target='blank'  className='fs-6' style={{textDecoration:"none",color:"black"}} to=''>Location Map</Link>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='text-center h6 pt-2 pb-3'>
                        CONNECT TO US
                        <div className='icons pt-3'>
                        <a href={`whatsapp://send?text=Application-for-Full Stack Developer ${window.location.href}`} rel="noreferrer noopener" target='_blank'><i style={{ color: "black" }} class="fa-brands ps-2 pe-2 fa-2x fa-facebook"></i></a>
                        <a href={`whatsapp://send?text=Application-for-Full Stack Developer ${window.location.href}`} rel="noreferrer noopener" target='_blank'><i style={{ color: "black" }} class="fa-brands ps-2 pe-2 fa-2x fa-x-twitter"></i></a>
                        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&text=hhcgg`} rel="noreferrer noopener" target='_blank'><i style={{ color: "black" }} class="fa-brands ps-2 pe-2 fa-2x fa-linkedin"></i></a>
                        <a href={`whatsapp://send?text=Application-for-Full Stack Developer ${window.location.href}`} rel="noreferrer noopener" target='_blank'><i style={{ color: "black" }} class="fa-brands p-2 fa-2x fa-whatsapp"></i></a>
                        <a href={`whatsapp://send?text=Application-for-Full Stack Developer ${window.location.href}`} rel="noreferrer noopener" target='_blank'><i style={{ color: "black" }} class="fa-brands ps-2 pe-2 fa-2x fa-telegram"></i></a>
                    </div>
                    </div>
                </div>
            </div>
            <div className='row text-start text-dark' style={{backgroundColor:"rgb(250,250,250)"}}>
                <Fade>
                    <div className='col-md-12 p-3' style={{fontWeight:"350" }}>
                            ©2024 All rights reserved
                    </div>
                </Fade>
                {/* <Fade><div className='col-md-4 p-3'>
                    <Link to='/privacy-policy' style={{ "textDecoration": "none", "color": "black",fontWeight:"350" }} >
                        PRIVACY POLICY
                    </Link>
                </div>
                </Fade>
                <Fade>
                    <div className='col-md-4 p-3'>
                        Designed and Developed By <Link to='/' style={{ "textDecoration": "none", "color": "black",fontWeight:"350" }}><b>HEYRAM INFRASTRUCTURE</b></Link>
                    </div>
                </Fade> */}
            </div>
        </div>
    </>
}

export default Footer