import React, { useState } from "react";
import axios from "axios";
import "../Intern/Internshipform.css";
import internFormImg from '../../Assets/internformImg.png'
import { toast } from 'react-toastify';
import { SERVER_API } from "../../global";

const InternshipForm = () => {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        graduationYear: "",
        location: "",
    });
    const [submitting, setSubmitting] = useState(false); // State to track submission status

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitting(true); // Set submitting to true while waiting for the response

        try {
            const response = await axios.post(
                `${SERVER_API}/intern/intern`,
                formData
            );
            toast.success("Form submitted successfully:", response.data);
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
                graduationYear: "",
                location: "",
                internRole: ""
            });
        } catch (error) {
            toast.error("Error submitting form:", error);
        } finally {
            setSubmitting(false); // Reset submitting state regardless of success or failure
        }
    };
    return (
        <div className="container-fluid intern-form-page">
            <div className="row intern-form-row">
                <div className="col-md-4 ">
                    <img src={internFormImg} style={{ borderRadius: "10px" }} className="w-100" alt="img" />
                </div>
                <div className="col-md-8">
                    <section className="container intern-forms">
                        <div>
                            <form onSubmit={handleSubmit} className="form">
                                <div className="column">
                                    <div className="input-box">
                                        <label>
                                            First Name <span className="mandatory">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="firstName"
                                            value={formData.firstName}
                                            onChange={handleChange} required
                                        />
                                    </div>
                                    <div className="input-box">
                                        <label>
                                            Last Name <span className="mandatory">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="lastName"
                                            value={formData.lastName}
                                            onChange={handleChange}
                                        />
                                    </div>
                                </div>

                                <div className="input-box">
                                    <label>
                                        Email<span className="mandatory">*</span>
                                    </label>
                                    <input
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange} required
                                    />
                                </div>
                                <div className="column">
                                    <div className="input-box">
                                        <label>
                                            Phone<span className="mandatory">*</span>
                                        </label>
                                        <input
                                            type="text"
                                            name="phone"
                                            value={formData.phone}
                                            onChange={handleChange} required
                                        />
                                    </div>

                                    <div className="input-box">
                                        <label>
                                            Graduation Year<span className="mandatory">*</span>
                                        </label>
                                        <input
                                            name="graduationYear"
                                            value={formData.graduationYear}
                                            onChange={handleChange} required
                                        />
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="input-box">
                                        <label>
                                            Location<span className="mandatory">*</span>
                                        </label>
                                        <input
                                            name="location"
                                            value={formData.location}
                                            onChange={handleChange} required
                                        />
                                    </div>
                                    <div className="input-box">
                                        <label>
                                            Internship Role <span className="mandatory">*</span>
                                        </label>
                                        <div className="role-input">
                                            <div className="combined-input">
                                                <select
                                                    name="internRole"
                                                    value={formData.internRole}
                                                    onChange={handleChange} required
                                                    className="select"
                                                >
                                                    <option value="">Select Internship Role-----</option>
                                                    <option value="Artificial Intelligence">Artificial Intelligence</option>
                                                    <option value="App Development">App Development</option>
                                                    <option value="Digital Marketing">Digital Marketing</option>
                                                    <option value="Web Development">Web Development</option>
                                                    <option value="Cloud Service">Cloud Services</option>
                                                    <option value="Finance Analysis">Finance Analysis</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-center ">
                                    <input
                                        className="button sub-button"
                                        type="submit"
                                        value={submitting ? "Submitting..." : "Submit"}
                                        disabled={submitting}
                                    />
                                </div>

                            </form>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default InternshipForm;