import React, { useState } from 'react'

export const AgriData = React.createContext()
function AgricultureData({children}) {
    let [data, setData] = useState([
        {
            name:"SAFFRON",
            img:"https://media.istockphoto.com/id/540601628/photo/raw-organic-red-saffron-spice.jpg?s=612x612&w=0&k=20&c=Ana18gJZs154u3ATgDMj_hmRw6kwLnPAiNtOtqL5Sbo=",
            description1:"Grade A1 Kashmir Saffron,The most aromatic, flavourful and potent saffron strain in the world from Pampore, Kashmir.",
            description2:"The most sought after and rarest grade of Malabari pepper. Extra-special, woody, citrusy and a lot more",
            path:""
        },
        {
            name:"SILK",
            img:"https://blog.fabrics-store.com/wp-content/uploads/2019/03/silk-worm.png",
            description1:"Silk has a smooth, soft texture that is not slippery, unlike many synthetic fibers. ",
            description2:"Silk is one of the strongest natural fibers, but it loses up to 20% of its strength when wet.",
            path:'/agricultural-services/silkworm' 
        }
    ])
    return <>
    <AgriData.Provider value={{data,setData}}>{children}</AgriData.Provider>
    </>
}

export default AgricultureData