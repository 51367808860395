import React, { useState, useEffect } from 'react';
import './css/Scrollbar.css'
import UA from '../Assets/up-arrow.png'
import DA from '../Assets/down-arrow.png'

function Scrollbar() {
    const [showDownArrow, setShowDownArrow] = useState(false);
    const [showUpArrow, setShowUpArrow] = useState(false);

    useEffect(() => {
        // Function to handle scroll event
        const handleScroll = () => {
            const isBottom = window.innerHeight + window.scrollY >= document.body.offsetHeight;
            setShowDownArrow(!isBottom);
            setShowUpArrow(isBottom);
        };

        // Attach the scroll event listener when component mounts
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener when component unmounts
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.body.offsetHeight,
            behavior: 'smooth'
        });
    };

    return (
        <div className="Scrollbar">
            {/* Your page content goes here */}
            <div style={{ height: '2000px' }}></div>

            {/* Floating arrow components */}
            {showDownArrow && (
                <div className="floating-arrow down-arrow" onClick={scrollToBottom}>
                    <img src={DA} alt="down arrow" />
                </div>
            )}

            {showUpArrow && (
                <div className="floating-arrow up-arrow" onClick={scrollToTop}>
                    <img src={UA} alt="up arrow" />
                </div>
            )}
        </div>
    );
}

export default Scrollbar;