import React, { useEffect } from 'react'
import './css/Privacy.css'

function PrivacyPlicy() {
    useEffect (() => {
        document.title = "Privacy policy"
        window.scrollTo(0, 0)
    }, [])
    return <>
        <div className='container-fluid'>
            <div className='row privacy-row'>
                <div className='col-md-12 ps-4 pe-2'>
                    <div className='h1 text-center pb-5'>
                        Privacy Policy
                    </div>
                    <p className='fs-5'>
                        Heyram Infrastructure we operate <a rel="noreferrer noopener" target='_blank' style={{ textDecoration: "none", fontSize: "1.5rem", fontWeight: "400" ,color:"black"}} href="http://www.heyraminfrastructure.com">HeyRam Infrastructure</a>. This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the Site.<br/><br/>

                        We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy.<br/><br/>

                        <h3> Information Collection and Use</h3>
                        While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally, identifiable information may include but is not limited to your name.<br/><br/>

                        <h3>Log Data</h3>
                        Like many site operators, we collect information that your browser sends whenever you visit our Site. This Log Data may include information such as your computer's Internet Protocol address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages, and other statistics.<br/><br/>

                        <h3>Cookies</h3>
                        Cookies are files with a small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.<br/>

                        Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.<br/><br/>

                        <h3>Security</h3>
                        The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.<br/><br/>

                        <h3>Changes to This Privacy Policy</h3>
                        Heyram Infrastructure may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Site. You are advised to review this Privacy Policy periodically for any changes.<br/><br/>

                        <h3> Contact Us </h3>
                        If you have any questions about this Privacy Policy, please contact us.</p><br/><br/>
                </div>
            </div>
        </div>
    </>
}

export default PrivacyPlicy