import React, { useState } from "react";
import "./EmailSender.css";
import { toast } from 'react-toastify';
import { SERVER_API } from "../../global";

function EmailSender() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    graduationYear: "",
    experience: "",
    currentEmployer: "",
    currentctc: "",
    expectedctc: "",
    noticePeriod: "",
    jobRole: "",
    location: "",
    resume: null,
  });
  // console.log(SERVER_API);
/*   console.log("SERVER_API");
 */
  const [submitting, setSubmitting] = useState(false);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (event) => {
    setFormData({ ...formData, resume: event.target.files[0] });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitting(true);

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }
   

    fetch(`${SERVER_API}/api/job`, {
      method: "POST",
      body: formDataToSend,
    })
      .then((response) => {
        if (response.ok) {
          toast.success("Resume sent successfully!");
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            graduationYear: "",
            experience: "",
            currentEmployer: "",
            currentctc: "",
            expectedctc: "",
            jobRole: "",
            noticePeriod: "",
            location: "",
            resume: null, // Resetting the resume field after successful submission
          });
          document.getElementById("resume-input").value = null;
        } else {
          throw new Error("Failed to send resume");
        }
      })
      .catch((error) => {
        // console.error("Error:", error);
        toast.error("Something Wrong. Please try again.");
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <section className="container">
      <div>
        <form onSubmit={handleSubmit} className="form">
          <div className="column">
            <div className="input-box">
              <label>
                First Name <span className="mandatory">*</span>
              </label>
              <input
                type="text"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange} required
              />
            </div>
            <div className="input-box">
              <label>
                Last Name <span className="mandatory">*</span>
              </label>
              <input
                type="text"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
            </div>
          </div>

          <div className="input-box">
            <label>
              Email<span className="mandatory">*</span>
            </label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange} required
            />
          </div>
          <div className="column">
            <div className="input-box">
              <label>
                Phone<span className="mandatory">*</span>
              </label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange} required
              />
            </div>

            <div className="input-box">
              <label>
                Graducation Year<span className="mandatory">*</span>
              </label>
              <input
                type="number"
                name="graduationYear"
                value={formData.graduationYear}
                onChange={handleInputChange} required
              />
            </div>
          </div>

          <div className="input-box">
            <label>
              Years of Experience<span className="mandatory">*</span>
            </label>
            <input
              type="number"
              name="experience"
              value={formData.experience}
              onChange={handleInputChange} required
            />
          </div>

          <div className="input-box">
            <label>
              Current Employer<span className="mandatory">*</span>
            </label>
            <input
              name="currentEmployer"
              value={formData.currentEmployer}
              onChange={handleInputChange} required
            />
          </div>
          <div className="column">
            <div className="input-box">
              <label>
                Current CTC<span className="mandatory">*</span>
              </label>
              <input
                type="number"
                name="currentctc"
                value={formData.currentctc}
                onChange={handleInputChange} required
              />
            </div>

            <div className="input-box">
              <label>
                Expected CTC<span className="mandatory">*</span>
              </label>
              <input
                type="number"
                name="expectedctc"
                value={formData.expectedctc}
                onChange={handleInputChange} required
              />
            </div>
          </div>
          <div className="column">
            <div className="input-box">
              <label>
                Location<span className="mandatory">*</span>
              </label>
              <input
                name="location"
                value={formData.location}
                onChange={handleInputChange} required
              />
            </div>
            <div className="input-box">
              <label>
                Job Role <span className="mandatory">*</span>
              </label>
              <div className="role-input">
                <div className="combined-input">
                  <select
                    name="jobRole"
                    value={formData.jobRole}
                    onChange={handleInputChange} required
                    className="select"
                  >
                    <option value="">Select Job Role -----------</option>
                    <option value="Full Stack Developer">
                      Full Stack Developer
                    </option>
                    <option value="Data Analyst">Data Analyst</option>
                    <option value="Digital Marketer">Digital Marketer</option>
                    <option value="Cloud Engineer">Cloud Engineer</option>
                    <option value="UI/UX Designer">UI/UX Designer</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="input-box">
              {" "}
              <label>
                Notice Period<span className="mandatory">*</span>
              </label>
              <input
                name="noticePeriod"
                value={formData.noticePeriod}
                onChange={handleInputChange} required
              />
            </div>

            <div className="input-box">
              <label>
                Resume<span className="mandatory">*</span>
              </label>
              <input
                id="resume-input"
                type="file"
                name="resume"
                /*  value={formData.resume} */
                onChange={handleFileChange} required
              />
            </div>
          </div>
          <div className="d-flex justify-content-center ">
            <input
              className="button sub-button"
              type="submit"
              value={submitting ? "Submitting..." : "Submit"}
              disabled={submitting}
            />
          </div>

        </form>
      </div>
    </section>
  );
}

export default EmailSender;
