import React from 'react'
import './JobFs.css'
import EmailSender from './EmailSender'


function UIUX() {
    return <>
        <div className='container-fluid '>
            <div className='row'>
                <div className='col-md-12 text-center job-title-card'>
                    <div style={{ fontSize: "1.5rem" }} className='pt-3 pb-3 company-title'>
                        HeyRam Infrastructure | Full Time
                    </div>
                    <div className='h1 pt-3 pb-3'>
                        UIUX
                    </div>
                    <div className='d-flex justify-content-center buttons'>
                        <div style={{ fontWeight: "350" }} >
                            SHARE IT TO
                        </div>
                    </div>
                    <div className='icons'>
                        <a href={`whatsapp://send?text=Application-for-Full Stack Developer ${window.location.href}`} rel="noreferrer noopener" target='_blank'><i style={{ color: "blue" }} class="fa-brands ps-2 pe-2 fa-3x fa-facebook"></i></a>
                        <a href={`whatsapp://send?text=Application-for-Full Stack Developer ${window.location.href}`} rel="noreferrer noopener" target='_blank'><i style={{ color: "black" }} class="fa-brands ps-2 pe-2 fa-3x fa-x-twitter"></i></a>
                        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&text=hhcgg`} rel="noreferrer noopener" target='_blank'><i class="fa-brands ps-2 pe-2 fa-3x fa-linkedin"></i></a>
                        <a href={`whatsapp://send?text=Application-for-Full Stack Developer ${window.location.href}`} rel="noreferrer noopener" target='_blank'><i style={{ color: "green" }} class="fa-brands p-2 fa-3x fa-whatsapp"></i></a>
                        <a href={`whatsapp://send?text=Application-for-Full Stack Developer ${window.location.href}`} rel="noreferrer noopener" target='_blank'><i class="fa-brands ps-2 pe-2 fa-3x fa-telegram"></i></a>
                    </div>
                </div>
            </div>
            <div className='row secn-row pt-5 ps-3 pe-3 pb-5'>
                <div className='col-md-8'>
                    <div className='h1 '>
                        Job Description
                    </div> <br />
                    <p className='pe-5 fs-5'><b className='fs-5'>Role Description:</b>We are seeking a talented UI/UX Designer to join our team. This is a full-time on-site role where you
                        will be responsible for designing user interfaces and experiences for our clients' digital products. You
                        will work closely with our development and marketing teams to create visually appealing and
                        userfriendly interfaces that enhance user experience and drive business growth.</p><br/><br/>
                    <div className='roles-respond'>
                        <div className='h4'>
                            Responsibilities:
                        </div><br/>
                        <ul>
                            <li className='fs-5'>
                                Create wireframes, mockups, and prototypes for digital products
                            </li><br/>
                            <li className='fs-5'>
                                Design user interfaces that are intuitive, easy to use, and visually appealing
                            </li><br/>
                            <li className='fs-5'>
                                Collaborate with cross-functional teams to define design requirements and ensure consistency across products
                            </li><br/>
                            <li className='fs-5'>
                                Conduct user research and usability testing to gather feedback and iterate on designs
                            </li><br/>
                            <li className='fs-5'>
                                Stay up-to-date with industry trends and best practices to continuously improve our design processes
                            </li><br/>
                        </ul>
                    </div>
                    <div className='roles-respond'>
                        <div className='h4'>
                            Qualifications:
                        </div><br/>
                        <ul>
                            <li className='fs-5'>
                                Freshers and Proven experience as a UI/UX Designer or similar role
                            </li><br/>
                            <li className='fs-5'>
                                Portfolio of design projects that demonstrate your skills and creative thinking
                            </li><br/>
                            <li className='fs-5'>
                                Proficiency in design tools such as Adobe XD, Sketch, or Figma
                            </li><br/>
                            <li className='fs-5'>
                                Strong understanding of user-centered design principles and best practices
                            </li><br/>
                            <li className='fs-5'>
                                Excellent communication and collaboration skills
                            </li><br/>
                        </ul>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='h1 '>
                        Job Information
                    </div>
                    <div className='fs-5 pt-1 pb-1'>
                        Country
                    </div>
                    <div className='h5'>
                        India
                    </div> <br />
                    <div className='fs-5 pt-1 pb-1'>
                        Industry
                    </div>
                    <div className='h5'>
                        Technology
                    </div>
                    <div className='form-area'>
                    <div className="pt-3 text-center h1 pb-5" >
                            Apply Now
                        </div>
                        <EmailSender />
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default UIUX