import React, { useState } from 'react'


export const JobData = React.createContext()
function JobContext({ children }) {
    let [data, setData] = useState([
        {
            name: "Full Stack Developer",
            description: "Design, develop, and maintain both front-end and back-end components of web applications. Collaborate with cross-functional teams to understand project...",
            path:"/jobs/full-stack-developer"
        },
        {
            name: "Data Analyst",
            description: "Analyze large datasets to extract meaningful insights and trends. Collaborate with cross-functional teams to identify business needs and translate them into...",
            path:"/jobs/data-analysis"
        },
        {
            name: "Digital Marketer",
            description: "Manage Google Adwords Campaigns to improve Return on Investment and generate reports Develop performance metrics to provide recommendations and continue...",
            path:"/jobs/digital-marketing"
        },
        {
            name: "Cloud Engineer",
            description: "Revolves around providing expert advice, guidance, and solutions to clients in various domains. Depending on your specialization, you may offer consultancy...",
            path:"/jobs/cloud-services"
        },
        {
            name: "UI/UX Designer",
            description: "We are searching for creative UI/UX designers to help us create intuitive and user-friendly interfaces for our software applications. If you have a...",
            path:"/jobs/uiux"
        }
    ])
    return <>
        <JobData.Provider value={{data,setData}}>{children}</JobData.Provider>
    </>
}

export default JobContext