import React from 'react'
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

function ACard({ input }) {

    return <>
        <Card style={{margin:"1rem"}}>
            <img src={input.img} className='w-100' alt="images" />
            <Card.Body className='card-b text-white' >
                <Link to='/agricultural-services/silkworm' style={{textDecoration:"none", color:"White"}}><Card.Title>{input.name}</Card.Title></Link>
                <Card.Text>
                    {input.description1}<br/>{input.description2}
                </Card.Text>
            </Card.Body>
        </Card>
    </>
}

export default ACard