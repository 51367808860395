import React from 'react'

function Terms() {
    return <>
        <div className='container-fluid'>
            <div className='row privacy-row'>
                <div className='col-md-12 ps-4 pe-2'>
                    <div className='h1 text-center pb-5'>
                        Terms of Use
                    </div>
                    <p className='fs-5'>
                        <h3>Welcome to heyraminfrastructure.com. These Terms of Use govern your use of the Website and the services provided by Hey Ram Infrastructure. By accessing or using the Website, you agree to be bound by these Terms of Use. If you do not agree to these terms, please do not use the Website.</h3><br /><br />
                        <h3>Acceptance of Terms</h3>
                        By accessing or using the Website, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use, which constitute a legally binding agreement. If you do not agree to these terms, you may not access or use the Website.<br /><br />

                        <h3> Services Provided</h3>
                        Hey Ram Infrastructure offers placement training and software solutions including web development, app development, digital marketing, and cloud services and more,  through the Website. The content and services provided are for informational and educational purposes only.<br /><br />

                        <h3> User Conduct</h3>
                        You agree to use the Website in accordance with all applicable laws, regulations, and these Terms of Use. You shall not:<br /><br />

                        <h3>Security</h3>
                        The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
                        Use the Website for any unlawful or prohibited purpose.
                        Interfere with the operation of the Website or other users' use and enjoyment of the Website.
                        Attempt to gain unauthorized access to any part of the Website.
                        Upload, post, or transmit any material that is unlawful, harmful, threatening, abusive, harassing, defamatory, obscene, libelous, invasive of another's privacy, or otherwise objectionable.<br /><br />

                        <h3>Intellectual Property</h3>
                        All content and materials on the Website, including but not limited to text, graphics, logos, images, videos, and software, are the property of Hey Ram Infrastructure or its licensors and are protected by copyright and other intellectual property laws. You may not reproduce, distribute, modify, or create derivative works of any content on the Website without the prior written consent of Hey Ram Infrastructure.<br /><br />

                        <h3> Third Party Content</h3>
                        The Website may contain links to third-party websites, products, or services. Hey Ram Infrastructure does not endorse or assume any responsibility for the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that Hey Ram Infrastructure shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods, or services available on or through any such third-party websites or services.<br /><br />

                        <h3> Disclaimer</h3>
                        The information provided on the Website is for general informational purposes only. Hey Ram Infrastructure makes no representations or warranties of any kind, express or implied, about the accuracy, reliability, or completeness of the information provided on the Website. Your use of the Website is at your own risk.<br /><br />

                        <h3> Limitation of Liability</h3>
                        In no event shall Hey Ram Infrastructure be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Website or the content contained therein. This limitation of liability shall apply to the fullest extent permitted by law.<br /><br />

                        <h3> Indemnification</h3>
                        You agree to indemnify, defend, and hold harmless Hey Ram Infrastructure, its officers, directors, employees, agents, and affiliates, from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising out of or in connection with your use of the Website or any violation of these Terms of Use.<br /><br />

                        <h3>  Governing Law</h3>
                        These Terms of Use shall be governed by and construed in accordance with the laws of your jurisdiction, without regard to its conflict of law provisions. Any dispute arising out of or in connection with these Terms of Use shall be subject to the exclusive jurisdiction of the courts of your jurisdiction.<br /><br />

                        <h3>Changes to Terms of Use</h3>
                        Hey Ram Infrastructure reserves the right to modify or revise these Terms of Use at any time without prior notice. Your continued use of the Website following the posting of any changes to these Terms of Use constitutes acceptance of those changes.<br /><br />

                        <h3> Contact Us </h3>
                        If you have any questions about this Privacy Policy, please contact us.</p><br /><br />
                </div>
            </div>
        </div>
    </>
}

export default Terms