import React from 'react'
import './JobFs.css'
import EmailSender from './EmailSender'


function DA() {
    return <>
        <div className='container-fluid '>
            <div className='row'>
                <div className='col-md-12 text-center job-title-card'>
                    <div style={{ fontSize: "1.5rem" }} className='pt-3 pb-3 company-title'>
                        HeyRam Infrastructure | Full Time
                    </div>
                    <div className='h1 pt-3 pb-3'>
                        AI/ML Engineer
                    </div>
                    <div className='d-flex justify-content-center buttons'>
                        <div style={{ fontWeight: "350" }} >
                            SHARE IT TO
                        </div>
                    </div>
                    <div className='icons'>
                        <a href={`whatsapp://send?text=Application-for-Full Stack Developer ${window.location.href}`} rel="noreferrer noopener" target='_blank'><i style={{ color: "blue" }} class="fa-brands ps-2 pe-2 fa-3x fa-facebook"></i></a>
                        <a href={`whatsapp://send?text=Application-for-Full Stack Developer ${window.location.href}`} rel="noreferrer noopener" target='_blank'><i style={{ color: "black" }} class="fa-brands ps-2 pe-2 fa-3x fa-x-twitter"></i></a>
                        <a href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}&text=hhcgg`} rel="noreferrer noopener" target='_blank'><i class="fa-brands ps-2 pe-2 fa-3x fa-linkedin"></i></a>
                        <a href={`whatsapp://send?text=Application-for-Full Stack Developer ${window.location.href}`} rel="noreferrer noopener" target='_blank'><i style={{ color: "green" }} class="fa-brands p-2 fa-3x fa-whatsapp"></i></a>
                        <a href={`whatsapp://send?text=Application-for-Full Stack Developer ${window.location.href}`} rel="noreferrer noopener" target='_blank'><i class="fa-brands ps-2 pe-2 fa-3x fa-telegram"></i></a>
                    </div>
                </div>
            </div>
            <div className='row secn-row pt-5 ps-3 pe-3 pb-5'>
                <div className='col-md-8'>
                    <div className='h1 '>
                        Job Description
                    </div> <br />
                    <p className='pe-5 fs-5'><b className='fs-5'>Role Description:</b> We are seeking a talented and motivated AI/ML Engineer to join our team. The role involves
                        developing, implementing, and optimizing AI/ML models and algorithms for various projects.
                        Responsibilities include collecting and preprocessing data, designing and training models, and
                        evaluating model performance. The AI/ML Engineer will work closely with the software
                        development team and report to the Chief Technology Officer.
                    </p><br/><br/>
                    <div className='roles-respond'>
                        <div className='h4'>
                            Responsibilities:
                        </div><br/>
                        <ul>
                            <li className='fs-5'>
                                Experience with natural language processing (NLP) and computer vision
                            </li><br/>
                            <li className='fs-5'>
                                Knowledge of cloud computing platforms such as AWS, Azure, or Google Cloud
                            </li><br/>
                            <li className='fs-5'>
                                Familiarity with version control systems such as Git
                            </li><br/>
                        </ul>
                    </div>
                    <div className='roles-respond'>
                        <div className='h4'>
                            Qualifications:
                        </div><br/>
                        <ul>
                            <li className='fs-5'>
                                Bachelor's or Master's degree in Computer Science, Data Science, or related field
                            </li><br/>
                            <li className='fs-5'>
                                Solid understanding of machine learning algorithms and concepts
                            </li><br/>
                            <li className='fs-5'>
                                Proficiency in programming languages such as Python, R, or Java
                            </li><br/>
                            <li className='fs-5'>
                                Experience with machine learning frameworks such as TensorFlow, PyTorch, or scikit-learn
                            </li><br/>
                            <li className='fs-5'>
                                Familiarity with data processing tools and libraries such as Pandas, NumPy, or Spark
                            </li><br/>
                            <li className='fs-5'>
                                Strong analytical and problem-solving skills
                            </li><br/>
                            <li className='fs-5'>
                                Excellent written and verbal communication skills
                            </li><br/>
                        </ul>
                    </div>
                </div>
                <div className='col-md-4'>
                    <div className='h1 '>
                        Job Information
                    </div>
                    <div className='fs-5 pt-1 pb-1'>
                        Country
                    </div>
                    <div className='h5'>
                        India
                    </div> <br />
                    <div className='fs-5 pt-1 pb-1'>
                        Industry
                    </div>
                    <div className='h5'>
                        Technology
                    </div>
                    <div className='form-area'>
                        <div className="pt-3 text-center h1 pb-5" >
                            Apply Now
                        </div>
                        <EmailSender />
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default DA