import React from 'react'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import Homepage from './Homepage'
import Genz from './Genz'
import AWS from './AWS'
import Crypto from './Crypto'
import Digital from './Digital'
import Agriculture from './Agriculture'
import ServiceContext from '../Context/ServiceContext'
import DigitalServiceData from '../Context/DigitalServiceData'
import AgricultureData from '../Context/AgricultureData'
import { AnimatePresence } from 'framer-motion'
import About from './About'
import Career from './Career'
import Jobs from './Jobs'
import JobContext from '../Context/JobContext'
import Intership from './Intership'
import IntershipData from '../Context/IntershipData'
import Wd from './Intern/Wd'
import FA from './Intern/FA'
import AD from './Intern/AD'
import CS from './Intern/CS'
import AI from './Intern/AI'
import DM from './Intern/DM'
import FSD from './Jobs/FSD'
import DA from './Jobs/DA'
import DMJ from './Jobs/DMJ'
import UIUX from './Jobs/UIUX'
import CJ from './Jobs/CJ'
import InternshipForm from './Intern/InternForm'
import Scrollbar from './Scrollbar'
import PrivacyPlicy from './PrivacyPlicy'
import GenzProject from './GenzProject'
import SilkWorm from './Agriculture/SilkWorm'
import Terms from './Terms'


function AnimatedRoutes() {
    const location = useLocation()

    return <>
        <AnimatePresence>
            <Routes location={location} key={location.pathname}>
                <Route path="/" element={<ServiceContext>
                    <Homepage />
                </ServiceContext>} />
                <Route path="/genz" element={<Genz />} />
                <Route path="/cloud-services" element={<AWS />} />
                <Route path='/about' element={<About />} />
                <Route path='/career' element={<Career />} />
                <Route path='/jobs' element={
                    <JobContext>
                        <Jobs />
                    </JobContext>
                } />
                <Route path='/internship' element={
                    <IntershipData>
                        <Intership />
                    </IntershipData>
                } />
                <Route path='/genz-project' element={<GenzProject />} />
                <Route path='/scrollbar' element={<Scrollbar />} />
                <Route path='/internship/web-developer-intern' element={<Wd />} />
                <Route path='/internship/app-developer-intern' element={<AD />} />
                <Route path='/internship/ai-developer-intern' element={<AI />} />
                <Route path='/internship/cloud-intern' element={<CS />} />
                <Route path='/internship/digital-marketing-intern' element={<DM />} />
                <Route path='/internship/financial-analyst-intern' element={<FA />} />
                <Route path='/digital-service' element={
                    <DigitalServiceData>
                        <Digital />
                    </DigitalServiceData>
                } />
                {/* <Route path='/job-apply-form' element={<EmailSender />} /> */}
                <Route path='/agricultural-services/silkworm' element={<SilkWorm />} />
                <Route path='/internship-apply-form' element={<InternshipForm />} />
                <Route path='/privacy-policy' element={<PrivacyPlicy />} />
                <Route path='/jobs/full-stack-developer' element={<FSD />} />
                <Route path='/jobs/data-analysis' element={<DA />} />
                <Route path='/jobs/cloud-services' element={<CJ />} />
                <Route path='/jobs/digital-marketing' element={<DMJ />} />
                <Route path='/jobs/uiux' element={<UIUX />} />
                <Route path='/terms-of-use' element={<Terms />} />
                <Route path='/crypto-mining' element={<Crypto />} />
                <Route path='/agriculture' element={
                    <AgricultureData>
                        <Agriculture />
                    </AgricultureData>} />
                <Route path='*' element={<Navigate to='/' />} />
            </Routes>
        </AnimatePresence>
    </>
}

export default AnimatedRoutes