import React, { useEffect } from 'react'
import './css/GenzProject.css'
import GenzPimg from '../Assets/genproj.png'

function GenzProject() {
    useEffect(() => {
        document.title = "Genz-AI Project"
        window.scrollTo(0, 0)
    }, [])
    return <>
        <div className='container-fluid'>
            <div className='row genz-p-row'>
                <div className='col-md-12 genz-project'>
                    {/* <div className='genz-project-title fs-1 text-center'>
                    Cultivating Entrepreneurial Minds for Tomorrow's Trailblazers
                    </div> */}
                    <div className='genz-proj-imag'>
                        <img className='w-100' src={GenzPimg} alt="GenzProjectImage"/>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default GenzProject